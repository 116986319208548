import React from 'react'

import './../styles/pageSpecific/services.scss'
import GeneralPageLayout from '../components/Layouts/GeneralPage'
import MainTextBlock from '../components/UI/MainTextBlock/MainTextBlock'
import SectionContainer from '../components/UI/Containers/SectionContainer'
import SectionHeader from '../components/UI/SectionHeader/SectionHeader'
import AngledContainer from '../components/UI/Containers/AngledContainer'
import CapabilityVideo from '../components/UI/Capabilities/CapabilityVideo/CapabilityVideo'

import ExpertCapabilities from '../components/UI/Capabilities/ExpertCapabilities/ExpertCapabilities'
import ThreeBoxRow from '../components/UI/Images/ThreeBoxRow/ThreeBoxRow'
import ServiceIcon from './../images/icons/capabilities/SoftwareSolutions.inline.svg'

const SoftwareSolutionsPage = () => {
  const pageInfo = {
    pageType: 'interior',
    title1: 'Software',
    title2: 'Solutions',
    breadcrumbs: ['Capabilities'],
    ctaButton: false,
    ctaButtonData: {
      text: '',
      url: '',
    },
  }

  return (
    <GeneralPageLayout pageData={pageInfo}>
      <div className="content">
        {/* Capabilities Video */}
        <SectionContainer
          id="serviceVideo"
          color="white"
          type="pressedEdgeRight"
          classes={['inlineChildren_half', 'capabilitySection']}
        >
          <section>
            <SectionHeader color="navy" alignment="left" size="med">
              Developing the <span>Right Solution</span>
            </SectionHeader>
            <MainTextBlock>
              <p>
                We provide full life-cycle development, management, training,
                and sustainment of Navy-wide enterprise level web-based software
                applications. We deploy and manage web applications hosted in
                DoD data centers and cloud environments.
              </p>
            </MainTextBlock>
          </section>
          <CapabilityVideo
            url="https://www.youtube.com/embed/TdpR6c6g1oQ?yt:cc=on"
            title="Precise Software Solutions Informational Video"
          />
        </SectionContainer>

        {/* Why Us */}
        <div id="detailsDisplayServices_Wrap">
          <AngledContainer
            id="detailsDisplayServices"
            color="navy"
            type="fullWidth"
            svgColor="grayToNavy"
          >
            <SectionHeader
              color="white"
              alignment="center"
              size="med"
              trim="true"
            >
              Providing <span>Expertise In</span>
            </SectionHeader>
          </AngledContainer>
          <ThreeBoxRow
            overlaying={true}
            boxContent={{
              first: {
                img: 'carbon:cloud-services',
                text: 'Modernizing legacy applications & Migrating Cloud Environments',
              },
              second: {
                img: 'carbon:lifesaver',
                text: 'Product Consulting and Help Desk Support',
              },
              third: {
                img: 'carbon:communication-unified',
                text: 'User Training & Materials Development',
              },
            }}
          />
        </div>
        {/* Expert Capabilities */}
        <SectionContainer id="" color="white" type="pressedEdgeLeft">
          <ExpertCapabilities
            serviceName="Software Solutions"
            ServiceIcon={ServiceIcon}
          />
        </SectionContainer>
      </div>
    </GeneralPageLayout>
  )
}

export default SoftwareSolutionsPage
